import React, { useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import { PhoneUtil } from 'utils';
import './index.scss';
import PHONE_ICON from 'dyl-components/utils/DateTimeUtils/PHONE_ICON';

const PhoneNumber = ({ phone, handleNumberClick, selectedPhone }) => (
    <div className={`OfficeView__numberContainer ${phone.phone === selectedPhone && "OfficeView__numberContainer--selected"}`} onClick={() => handleNumberClick(phone.phone)}>
        <Icon className={`${PHONE_ICON[phone.phone_type]} OfficeView__numberIcon`}  />
        <span className='OfficeView__number'>{PhoneUtil.formatPhoneNumber(phone.phone)}</span>
        <Icon className={`fa-solid fa-star OfficeView__numberStar ${phone.main && "OfficeView__numberStar--primary"}`} />
    </div>
)

const OfficeViewMiddleNumbers = ({ person, onNumberClick, currentCallPhoneNumber }) => {
    const [selectedPhone, setSelectedPhone] = useState(null);

    const phones = person?.contact_id ? person?.contact_details?.phone || [] : person?.account_details?.phone || [];

    const handleNumberClick = (phone) => {
        setSelectedPhone(phone);
        onNumberClick(phone)
    }

    useEffect(() => {
        if (phones.length > 0) {
            setSelectedPhone(currentCallPhoneNumber || phones[0].phone || null);
        }
    }, [phones])

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
            height: "100%",
            marginTop: 20,
            paddingTop: 10,
            overflow: 'auto'
        }}>
            {phones.map((phone) => (
                <PhoneNumber
                    phone={phone}
                    handleNumberClick={handleNumberClick}
                    selectedPhone={selectedPhone}
                />
            ))}
        </div>
    )
}

export default OfficeViewMiddleNumbers;