import React from 'react';
import { PhoneButton } from 'dyl-components';
import {  Grid } from 'semantic-ui-react';

const OfficeViewAnswerButtons = ({ 
    ignore,
    ignoreDisabled = false,
    answer,
    answerDisabled = false,
    actionButtons,
 }) => {
    return (
        <Grid className="OfficeViewAnswerButtons" columns="equal">
            <Grid.Row>
                <Grid.Column>
                    <PhoneButton text="Answer" icon="fa-solid fa-phone" iconColor="blue" onClick={answer} disabled={answerDisabled} isClicked={actionButtons.answer} />
                </Grid.Column>
                <Grid.Column>
                    <PhoneButton text="Ignore" icon="fa-solid fa-phone-hangup" iconColor="red" onClick={ignore} disabled={ignoreDisabled} isClicked={actionButtons.hangup}/>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
};

export default OfficeViewAnswerButtons;