import React, { useEffect, useRef, useState } from 'react';

import './index.scss';
import { Grid, Icon, Popup, Segment } from 'semantic-ui-react';
import EmptyListPlaceholder from '../EmptyListPlaceholder';
import Person from '../Person';
import ButtonLink from 'dyl-components/atoms/ButtonLink';

const OfficeViewTeamsSearchResults = ({ searchResults, isReadingExtensionsTeamsList, togglePinnedTeams, pinsMaxed }) => {
    const [isTeamsTruncated, setIsTeamsTruncated] = useState(false);
    const teamsRef = useRef(null);

    useEffect(() => {
        if (teamsRef?.current) {
            const { scrollWidth, clientWidth } = teamsRef.current;
            if (scrollWidth > clientWidth) {
                setIsTeamsTruncated(true);
            } else {
                setIsTeamsTruncated(false);
            }
        } else {
            setIsTeamsTruncated(false);
        }
    }, [teamsRef])

    return (
        <div className='OfficeViewTeamsResults__container'>
            <Segment basic>
                {searchResults.length > 0 ? (
                    <Grid>
                        {searchResults.map((user, idx) => {
                            return (
                                <Grid.Row
                                    verticalAlign='middle'
                                    className='OfficeViewTeamsResults_rowContainer'
                                    key={idx}
                                    columns='equal'
                                    stretched
                                >
                                    <Grid.Column>
                                        <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
                                            <Person username={ `${user.first_name} ${user.last_name}` } email={`Ext. ${ user.extension_number }`} />
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Popup
                                            basic
                                            on={"click"}
                                            position='bottom center'
                                            disabled={!isTeamsTruncated}
                                            trigger={
                                                <div ref={teamsRef} className='OfficeViewTeams__clippedTrigger'>
                                                    {user.teams?.map(({name}, idx) => <span>{name}{user.teams.length - 1 === idx ? "" : ", "}</span>)}
                                                </div>
                                            }
                                            content={
                                                <div className='OfficeViewTeams__clippedContent'>
                                                    {user.teams?.map(({name}, idx) => {
                                                        return <div style={{marginRight: 50, marginBottom: idx === user.teams.length - 1 ? 0 : 7}}>
                                                            <span>{name}</span>
                                                        </div>
                                                    })}
                                                </div>
                                            }
                                        />
                                    </Grid.Column>
                                    <div style={{ display:'flex', alignItems: 'center' }}>
                                        <ButtonLink disabled={!user.pinned && pinsMaxed} onClick={(event) => {
                                            togglePinnedTeams(user.extension_id, !user.pinned)
                                            event.stopPropagation();
                                        }}>
                                            <Icon className={`fa-solid fa-thumbtack OfficeViewTeamsResults__linkIcon${user.pinned ? "--pinned" : (pinsMaxed ? "--disabled" : "")}`} size={"large"} />
                                        </ButtonLink>
                                    </div> 
                                </Grid.Row>
                            )
                        })}
                    </Grid>
            ) : (
                isReadingExtensionsTeamsList ? (
                    <Segment basic loading />
                ) : (
                    <EmptyListPlaceholder
                        title={"No results found"}
                        subtitle={' '}
                    />
                )
            )}
            </Segment>
        </div>
    )
}

export default OfficeViewTeamsSearchResults;
